import React, { useState } from 'react';
import ReactRotatingText from "react-rotating-text";
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import Image from '../elements/Image';
import Modal from '../elements/Modal';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

/*
  const [videoModalActive, setVideomodalactive] = useState(false);

  const openModal = (e) => {
    e.preventDefault();
    setVideomodalactive(true);
  }

  const closeModal = (e) => {
    e.preventDefault();
    setVideomodalactive(false);
  }   
*/

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const content = ['scales', 'works', 'amazes', 'empowers', 'fulfils'];

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container-sm mt-140 mb-140">
        <div className={innerClasses}>
          <div className="hero-figure reveal-from-bottom illustration-element-01" data-reveal-value="20px" data-reveal-delay="800">
            {/*
            <a
              data-video="https://player.vimeo.com/video/174002812"
              href="#0"
              aria-controls="video-modal"
              onClick={openModal}
            >
*/}
{/*            <Image
                className="has-shadow"
                src={require('./../../assets/images/hero2.svg')}
                alt="Hero"
                width={896}
                height={504} />*/}
            {/*
            </a>
*/}
          </div>
          <div className="hero-content">
            <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
              Baking software that <span className="text-color-primary">
                <ReactRotatingText
                    // random={true}
                    items={content}
                    emptyPause={250}
                    pause={2000}
                    typingInterval={70}
              />
              </span>
            </h1>
            <div className="container-xs">
              <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
                We design, build and deploy your awesome ideas.
                </p>
              <div className="reveal-from-bottom" data-reveal-delay="600">
                <ButtonGroup>
                  <Button tag="a" color="primary" wideMobile href="mailto: hello@bittencookie.pt">
                    Talk with us
                  </Button>
                  <Button tag="a" color="dark" wideMobile href="#projects">
                    View our projects
                  </Button>
                </ButtonGroup>
              </div>
            </div>
          </div>
{/*
          <Modal
            id="video-modal"
            show={videoModalActive}
            handleClose={closeModal}
            video="https://player.vimeo.com/video/174002812"
            videoTag="iframe" />
*/}
        </div>
      </div>
    </section>
  );
}

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;