import React from 'react';
import { IconRocket, IconRotateRectangle, IconShieldCheck, IconAtom, IconRun, IconCrown } from '@tabler/icons';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'Why work with us?',
    paragraph: 'We strive everyday to innovate and bring value to our customers.'
  };

  const iconsSize = 32;

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>
            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <IconAtom
                        size={iconsSize}
                        color="white"
                        stroke={1}
                        strokeLinejoin="miter"
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Leading Technology
                    </h4>
                  <p className="m-0 text-sm">
                    We build world-class software that is reliant, performant and predictable. And it works.
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <IconCrown
                        size={iconsSize}
                        color="white"
                        stroke={1}
                        strokeLinejoin="miter"
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Unrivaled Innovation
                    </h4>
                  <p className="m-0 text-sm">
                    We look at problems from different angles.
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <IconRotateRectangle
                        size={iconsSize}
                        color="white"
                        stroke={1}
                        strokeLinejoin="miter"
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Predictable Outcome
                    </h4>
                  <p className="m-0 text-sm">
                    You will always know the whats, hows and whens. So your journey with us is safe and sound.
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <IconShieldCheck
                        size={iconsSize}
                        color="white"
                        stroke={1}
                        strokeLinejoin="miter"
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Best Partners
                  </h4>
                  <p className="m-0 text-sm">
                    We don't simply fix the issue. We think about it. We dream about it. And we solve it.
                  </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <IconRun
                        size={iconsSize}
                        color="white"
                        stroke={1}
                        strokeLinejoin="miter"
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Move Fast
                    </h4>
                  <p className="m-0 text-sm">
                    We know time-to-market is an important variable to every business.
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <IconRocket
                        size={iconsSize}
                        color="white"
                        stroke={1}
                        strokeLinejoin="miter"
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Bold Vision
                    </h4>
                  <p className="m-0 text-sm">
                    Long-standing, traditional industries are disrupted by small tech companies all the time. We help you stay afloat.
                    </p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;